.app {
  display: flex;
  flex-direction: column;
  padding: 40px;
  position: relative;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}
.languageWrapper {
  user-select: none;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  top: 20px;
  right: 20px;
  width: fit-content;
  height: 30px;
  border-radius: 3px;
  border: 1px solid #000000;
  padding: 0 10px;
}
.languageHeader {
  width: 65px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 10px;
  justify-content: space-between;
}
.languageWrapper span {
  margin-bottom: -2px;
}
.flag {
  width: 15px;
  height: 15px;
}
.languageDropdown {
  position: absolute;
  width: calc(100% + 2px);
  height: fit-content;
  top: 35px;
  left: -1px;
  border-radius: 3px;
  border: 1px solid #000000;
  display: none;
  flex-direction: column;
  padding: 5px 0;
  background-color: #ffffff;
}

.languageDropdownOpen {
  display: flex;
}

.languageOption {
  padding: 5px 10px;
  width: 100%;
  color: #000000;
  background-color: #ffffff;
  transition: 0.3s ease;
}
.languageOption:hover {
  color: #ffffff;
  background-color: #000000;
}

.shape1 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  max-width: 100%;
}

.shape2 {
  position: absolute;
  top: 0;
  left: 20px;
  height: 120%;
  width: 100%;
  z-index: -1;
}
@media only screen and (max-width: 1000px) {
  .shape2 {
    top: -20px;
    left: -100px;
    width: 150%;
  }
}

.shape3 {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.about-me {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
}

.about-me-image-wrapper {
  width: 50%;
}

.first-image {
  border-radius: 20px;
}

.about-me-image {
  pointer-events: none;
  object-fit: cover;
  width: 100%;
  border-radius: 20px;
}

.about-me-div {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 50%;
  padding: 0 80px 0 100px;
  position: relative;
}

.about-me-text {
  font-size: 16px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1100px) {
  .app {
    padding: 10px;
  }
  .about-me {
    flex-direction: column-reverse;
    margin-top: 10px;
  }
  .about-me-image-wrapper {
    width: 100%;
  }
  .about-me-image {
    max-width: 100%;
    max-height: 400px;
    margin-top: 20px;
  }
  .about-me-div {
    width: 100%;
    padding: 0;
  }
}

.title {
  font-size: 50px;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
}

@media only screen and (max-width: 1100px) {
  .title {
    margin-top: 60px;
    font-size: 35px;
  }
}

.title-name {
  font-size: 25px;
  font-weight: 400;
  text-align: center;
}

.subtitle {
  font-size: 35px;
  font-weight: 500;
}

.w-50 {
  width: 50%;
}

.donation-section {
  display: flex;
  flex-direction: column;
  padding: 0 40px;
  margin-top: 50px;
}

.donation-div {
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #f1e9fe;
}

.donation-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.barcode-image {
  max-width: 55%;
}

.iban-info {
  display: flex;
  flex-direction: column;
  font-weight: 700;
}
.donation-iban-wrapper {
  position: relative;
}
.donation-iban {
  cursor: pointer;
}
.copyWrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 100%;
  width: 80px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: #000000;
  border-radius: 5px;
  border: 1px solid #000000;
  font-size: 16px;
  opacity: 0;
  transition: 0.3s ease;
}
.copyWrapperOpen {
  opacity: 1;
}

.margin-right-iban {
  margin: 0 160px 0 0;
}

@media only screen and (max-width: 1100px) {
  .donation-section {
    padding: 0 0;
  }
  .donation-div {
    padding: 10px;
  }
  .barcode-image {
    max-width: 100%;
  }
  .donation-info {
    flex-direction: column;
  }
  .margin-right-iban {
    margin: 0 ;
  }
}

.align-center {
  align-items: center;
}

.m-0 {
  margin: 0;
}

.mr-60 {
  margin-right: 60px;
}

#wrapper {
  width: 100%;
  align-self: center;
  position: relative;
  margin-top: 60px;
  margin-bottom: 100px;
}

#carousel {
  scroll-behavior: smooth;
  scrollbar-width: none;
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: visible;
}

#carousel::-webkit-scrollbar {
  height: 0;
  display: none;
}

#carousel-image {
  width: 290px;
  height: 384px;
  min-width: 290px;
  min-height: 384px;
  margin: 0 10px;
  object-fit: cover;
}

#prev,
#next {
  display: flex;
  justify-content: center;
  align-content: center;
  background: white;
  padding: 8px;
  border-radius: 50%;
  outline: 0;
  cursor: pointer;
  position: absolute;
  border: 2px solid #b4b4b4;
  z-index: 2;
}

#prev {
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
}

#next {
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
}
@media only screen and (max-width: 1100px) {
  #prev {
    top: 50%;
    left: 10px;
  }
  #next {
    top: 50%;
    right: 10px;
  }
}
